<template>
  <div class="home">
    <v-app>
      <v-container fluid>
        <v-card height="90vh" class="pt-8 mt-6">
          <v-app-bar class="navbar" app height="50px">
            <div class="header-title">
              <div class="d-flex align-center">
                <h1>เมนูหลัก</h1>
              </div>
              <v-btn icon dark class="pt-2" @click.prevent="$router.push('/cart')">
                <v-badge right color="red" overlap :content="`${this.$store.getters.getShoppingCart.length}`">
                  <v-icon class="ma-1">mdi-cart</v-icon>
                </v-badge>
              </v-btn>
            </div>
          </v-app-bar>
          <div class="d-flex flex-column justify-center align-center pt-4 pb-5">
            <v-btn
              class="btn-selct-ingredient mb-4"
              color="#2E2253"
              outlined
              width="200px"
              height="200px"
              @click.prevent="$router.push('/shopping-goods')"
            >
              <div class="wrapper-inner-btn">
                <div>
                  <img width="100px" src="../assets/ingredient.svg" alt="" />
                </div>
                <h1>เลือกวัตถุดิบ</h1>
              </div>
            </v-btn>
            <v-btn
              disabled
              color="#2E2253"
              outlined
              width="200px"
              height="200px"
            >
              <div class="wrapper-inner-btn">
                <h1>Coming Soon..</h1>
              </div>
            </v-btn>
          </div>
        </v-card>
      </v-container>
      <Footer />
    </v-app>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import liff from '@line/liff'
export default {
  name: "Home",
  components: {
    Footer,
  },
  async created() {
      await liff.init({ liffId: "1656130154-dRLb8BGe" }, () => {
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          this.liffGetProfile();
        }
      },
      err => console.log(err.code, err.message)
    );
  },
  methods: {
    liffGetProfile() {
        liff.getProfile()
            .then(profile => {
              console.log(profile);
              this.$store.dispatch("setLineUid", profile.userId)
            })
        .catch(err => console.log(err));
    },
  }
};
</script>

<style scoped>
.navbar {
  position: absolute !important;
  width: 95%;
  top: -24px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background: #2e2253 !important;
  border-radius: 3px !important;
}
.header-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-title h1 {
  color: #fff;
  font-weight: 400;
  font-size: 19px;
}
/* ***Header*** */

.btn-selct-ingredient {
  border: 3px solid #2e2253;
}
.wrapper-inner-btn h1 {
  font-weight: 500;
  font-size: 22px;
}
.btn-prev {
  display: none !important;
  opacity: 0 !important;
}
</style>